html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

body {
  background: white;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}
